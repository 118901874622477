import React from 'react';
import '../src/assets/css/App.css';
import { BrowserRouter } from 'react-router-dom';
import Header from "../src/pages/Header"
import Banner from "../src/pages/Home/Banner"
import WhyChoose from "../src/pages/Home/WhyChoose"
import OurPackage from "../src/pages/Home/OurPackage"
import AboutEdupine from "../src/pages/Home/AboutEdupine"
import OurMission from "../src/pages/Home/OurMission"
import Services from "../src/pages/Home/Services"
import DigitalPresence from "../src/pages/Home/DigitalPresence"
import Contact from "../src/pages/Home/Contact"
import Footer from "../src/pages/Footer"
import BasicPlan from './pages/Home/BasicPlan';
function App() {
  return (
    <>
    <BrowserRouter>
      <Header />
      <section id="home">
      <Banner />
      </section>
      <WhyChoose />
      <OurPackage />
      <section id="about">
      <AboutEdupine />
      </section>
      <OurMission />
      <section id="services">
      <Services/>
      </section>
      <section id="packages">
      <BasicPlan />
      </section>
      <DigitalPresence />
      <section id="contact">
      <Contact />
      </section>
      <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
