import React from "react"
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import logo from "../assets/images/logo-dark.svg"
const Footer = () => {
    return (
        <>
            <div className="container">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-6 mt-24">
                    <div>
                        <img src={logo} alt="logo" className="inline-block w-[163px]" />
                    </div>
                    <div className="text-end md:text-center">
                        <h3 className="text-sm md:text-lg text-secondary font-bold mb-3">Quick Links:</h3>
                        <ul>
                            <li className="mb-1">
                            <HashLink smooth to="#home" className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal">
                                    Home
                                </HashLink>
                            </li>
                            <li className="mb-1">
                            <HashLink smooth to="#about" className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal">
                                    About Us
                                    </HashLink>
                            </li>
                            <li className="mb-1">
                            <HashLink smooth to="#services" className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal">
                                    Services
                                    </HashLink>
                            </li>
                            <li className="mb-1">
                            <HashLink smooth to="#packages" className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal">
                                    Packages
                                    </HashLink>
                            </li>
                            <li className="mb-1">
                            <HashLink smooth to="#contact" className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal">
                                    Contact Us
                                    </HashLink>
                            </li>
                        </ul>
                    </div>
                    <div className="md:text-end col-span-2 md:col-span-1 ">
                        <h3 className="text-sm md:text-lg text-secondary font-bold mb-3">Contact Information:</h3>
                        <ul>
                            <li className="mb-1">
                                <div className="flex md:justify-end">
                                    <p className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal">
                                        Phone:
                                    </p>
                                    <Link to="tel:+98793 72759" className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal ml-1">
                                    98793 72759
                                    </Link>
                                </div>
                            </li>
                            <li className="mb-1">
                                <div className="flex md:justify-end">
                                    <p className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal">
                                        Email:
                                    </p>
                                    <Link to="mailto:sales@edupine.in" className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal ml-1">
                                    hello@edupine.in
                                    </Link>
                                </div>
                            </li>
                            <li className="mb-1">
                                <div className="flex md:justify-end">
                                    <p className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal">
                                        Address:
                                    </p>
                                    <p className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal max-w-full xl:max-w-[316px] ml-1">
                                    Ganesh Meridian, C-1005 A, Sarkhej - Gandhinagar Hwy, opp.
                                    Kargil Petrolpump, Sola, Ahmedabad, Gujarat 380060
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="border-b border-[#6C757D] mb-8 pb-8"></div>
                <div className="grid grid-cols-2 items-center gap-3 md:gap-5 mb-5">
                    <div>
                        <p className="text-[#6C757D] font-normal text-xs md:text-base">© 2024 .All Rights Reserved</p>
                    </div>
                    {/* <div>
                        <ul className="flex items-center justify-end">
                            <li>
                                <Link to="" className="text-[#6C757D] text-base md:text-xl mr-2 md:mr-3">
                                    <i className="fab fa-facebook-square"></i>
                                </Link>
                            </li>
                            <li>
                                <Link to="" className="text-[#6C757D] text-base md:text-xl mr-2 md:mr-3">
                                    <i className="fab fa-twitter"></i>
                                </Link>
                            </li>
                            <li>
                                <Link to="" className="text-[#6C757D] text-base md:text-xl mr-2 md:mr-3">
                                    <i className="fab fa-instagram-square"></i>
                                </Link>
                            </li>
                            <li>
                                <Link to="" className="text-[#6C757D] text-base md:text-xl mr-2 md:mr-3">
                                    <i className="fab fa-youtube"></i>
                                </Link>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </>
    )
}
export default Footer;